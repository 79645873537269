<template>
  <div class="aops-agreement-management" v-if="hasResource('agreement_management')">
    <div class="search-form">
      <div class="dis-fix">
        <el-form :inline="true">
          <el-form-item label="应用端">
            <el-select v-model="filter.client" placeholder="请选择">
              <el-option label="全部" value></el-option>
              <el-option label="INCEPTION" value="2"></el-option>
              <el-option label="PBRMAX" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="filter.scene" placeholder="请选择">
              <el-option label="全部" value></el-option>
              <el-option
                v-for="(item, key) in scene"
                :key="key"
                :label="getScene(item)"
                :value="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市场"
            ><el-select v-model="filter.market" placeholder="请选择">
              <el-option label="全部" value></el-option>
              <el-option
                v-for="(item, key) in market"
                :key="key"
                :label="item === 'China' ? '国内市场' : '国外市场'"
                :value="key"
              ></el-option> </el-select
          ></el-form-item>
          <el-form-item label="状态">
            <el-select v-model="filter.status" placeholder="请选择">
              <el-option label="全部" value></el-option>
              <el-option label="未配置" value="0"></el-option>
              <el-option label="已配置" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="text-btn">
          <span
            class="serach-button"
            @click="handleQuery"
            v-loading="isQueryLoading"
            >查询</span
          >
          <span class="clear-button" @click="resetQuery">清空</span>
        </div>
      </div>
    </div>
    <el-table
      :data="roleData"
      header-cell-class-name="table-head"
      cell-class-name="table-cell"
      class="data-table aop-table"
      v-loading="isTableLoading"
      :row-class-name="tableRowClassName"
      @sort-change="sortChange"
    >
      <el-table-column
        v-for="col in tableColumns"
        :key="col.label"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :minWidth="col.minWidth"
        :align="col.align"
        :fixed="col.fixed">
        <template slot-scope="{row}">
          <div v-if="col.prop === 'client'">
           <span>{{ `${row.client}` === '1' ? "PBRMAX" : "INCEPTION" }}</span>
          </div>
          <div v-else-if="col.prop === 'scene'">
           <span>{{getScene(row.scene)}}</span>
          </div>
          <div v-else-if="col.prop === 'market'">
           <span>{{ row.market === "US" ? "国外市场" : "国内市场" }}</span>
          </div>
          <div v-else-if="col.prop === 'status'">
            <span>{{ row.status ? "已配置" : "未配置" }}</span>
          </div>
          <div v-else-if="col.prop === 'operation'" class="icon-btn">
            <el-button
              size="mini"
              type="text"
              @click="previewAgreement(row)"
              v-if="hasResource('agreement_info') && row.status === 1"
            >
                <i class="iconfont">&#xe662;</i>预览
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="goEdit(row)"
              v-if="
                (row.status == 0 && hasResource('agreement_edit')) ||
                (row.status == 1 && hasResource('agreement_update'))">
                <i class="iconfont">&#xe677;</i>编辑
            </el-button>
            <el-button
               v-if="`${row.client}` === '1' && `${row.status}` === '1' "
                size="mini"
                type="text"
                v-clipboard:copy="getUrl(row)"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError">
                <i class="iconfont">&#xe69a;</i> 复制链接
            </el-button>
          </div>
          <div v-else>{{row[col.prop] || '--'}}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        v-if="total != 0"
      ></el-pagination>
    </div>
    <!-- 预览弹窗 -->
    <viewDialog
        :dialog-view.sync="dialogVisiblePreview"
        :data="{...preview, time:preview.updated_time}"
        :langData="langData"
        :leftIcon="false"
    />
    <!-- 确认删除弹框 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleStop"
      width="458px"
      class="pop-dialog"
      :modal-append-to-body="false"
    >
      <span class="fw-700 fz-18">是否确认删除该协议？</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          :loading="isDialogStopLoading"
          @click="confirmStop"
          class="confirm-button"
          >确 定</el-button
        >
        <el-button @click="dialogVisibleStop = false" class="cancel-button"
          >取 消</el-button
        >
      </span>
    </el-dialog>

    <!-- 确认发布弹框 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleRelease"
      width="458px"
      class="pop-dialog"
      :modal-append-to-body="false"
    >
      <p v-if="releaseError" class="fz-14 line-height-15">
        相同的场景及市场仅可存在一个发布状态的协议，确认发布的话将会替换原本已发布的协议。
      </p>
      <div class="mg-t-10">
        <span>确认发布</span>
        <span class="fz-32">《{{ release.name }}》</span>
        <span class="fz-32">{{ release.version }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="!releaseError"
          :loading="isDialogReleaseLoading"
          @click="confirmRelease"
          class="confirm-button"
          >确 定</el-button
        >
        <el-button
          v-if="releaseError"
          :loading="isDialogReleaseLoading"
          @click="confirmRelease"
          class="confirm-button"
          >发 布</el-button
        >
        <el-button @click="dialogVisibleRelease = false" class="cancel-button"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../../api/aggrement";
import commonApi from "../../../api/common";
import env from 'env';
import viewDialog from '@/pages/_common/viewDialog/viewDialog.vue';
import { langData, getScene } from './config'
export default {
  components: {
    viewDialog
  },
  data() {
    return {
      langData,
      tableColumns: [
        { prop: 'client', label: '应用端', minWidth: 200, align: 'left', },
        { prop: 'scene', label: '类型', minWidth: 190, align: 'left' },
        { prop: 'market', label: '市场', minWidth: 140, align: 'left' },
        { prop: 'version', label: '版本号', minWidth: 140, align: 'left' },
        { prop: 'updated_at', label: '更新时间', minWidth: 180, align: 'left' },
        { prop: 'status', label: '状态',minWidth: 140, align: 'left'},
        { prop: 'operation', label: '操作', minWidth: 360, align: 'left', fixed: 'right'  }
      ],
      roleData: [],
      currentPage: 1,
      pagesize: 10,
      total: 0,
      filter: {
        name: "",
        status: "",
        market: "",
        scene: "",
        client: "",
      },
      order_by: "id",
      sort: "ASC",
      market: "",
      scene: "",
      isQueryLoading: false,
      isTableLoading: false,
      isDialogAddLoading: false,
      isDialogStopLoading: false,
      isDialogReleaseLoading: false,
      dialogVisibleAdd: false,
      dialogVisibleUpdate: false,
      dialogVisibleStop: false,
      dialogVisiblePreview: false,
      dialogVisibleRelease: false,
      preview: {},
      release: {
        name: "",
        version: "",
      },
      delete: {
        name: "",
        version: "",
      },
      stopRowId: 0,
      header: {
        url: "agreementManagement",
      },
      releaseError: false,
    };
  },
  methods: {
    async init() {
      if (this.getLocalCurrentPage("needUpdate")) {
        await this.updateFilterParams();
        await this.getAgreementList();
        this.recordCurrentPage("needUpdate", false);
      } else {
        await this.getAgreementList();
      }
      this.getAllMarkets();
      this.getAgreementScene();
    },
    getAgreementScene() {
      api.agreementScene().then((res) => {
        if (res.data.code === 10200) {
          this.scene = res.data.data;
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    getAgreementList() {
      let params = {
        limit: this.pagesize,
        page: this.currentPage,
        filter: {
          market: this.filter.market,
          scene: this.filter.scene,
          status: this.filter.status,
          client: this.filter.client,
        },
        order_by: this.order_by,
        sort: this.sort,
      };
      this.isTableLoading = true;
      api.agreementList(params).then((res) => {
        if (res.data.code === 10200) {
          this.roleData = res.data.data.items;
          this.total = res.data.data.total;
          if (this.roleData.length <= 0 && this.currentPage > 1) {
            this.currentPage = 1;
            this.getAgreementList();
          }
        }
        this.isTableLoading = false;
        this.isQueryLoading = false;
      });
    },
    updateFilterParams() {
      this.recordData = this.getLocalCurrentPage("agreementList0415");
      let params = this.recordData;
      if (params) {
        this.parameters = true;
        this.pagesize = params ? params.limit : this.pagesize;
        this.currentPage = params ? params.page : this.currentPage;
        this.filter.market = params ? params.market : this.filter.market;
        this.filter.status = params ? params.status : this.filter.status;
        this.filter.scene = params ? params.scene : this.filter.scene;
        this.filter.client = params.client || this.filter.client || "";
      }
    },
    getAllMarkets() {
      commonApi.market().then((res) => {
        if (res.data.code === 10200) {
          this.market = res.data.data;
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    previewAgreement(row) {
      this.preview = row;
      if(this.preview.updated_at){
        this.preview.updated_time = this.preview.updated_at.slice(0,10)
      }
      this.dialogVisiblePreview = true;
    },
    confirmRelease() {
      let id = this.release.id;
      this.isDialogReleaseLoading = true;
      let params = {
        id: id,
        force: this.releaseError,
      };
      api.agreementRelease(params).then((res) => {
        if (res.data.code === 10200) {
          this.$$success("发布成功");
          this.getAgreementList();
          this.dialogVisibleRelease = false;
        } else {
          this.releaseError = true;
        }
        this.isDialogReleaseLoading = false;
      });
    },
    // 搜索
    handleQuery() {
      this.isQueryLoading = true;
      this.currentPage = 1;
      this.getAgreementList();
    },
    // 重置
    resetQuery() {
      this.filter = {
        name: "",
        status: "",
        market: "",
        scene: "",
        client: "",
      };
    },

    //确认停用
    confirmStop() {
      let id = this.stopRowId;
      this.isDialogStopLoading = true;
      api.aggrementDelete(id).then((res) => {
        if (res.data.code === 10200) {
          this.getAgreementList();
          this.$$success("成功");
        } else {
          this.$$error(res.data.message);
        }
        this.isDialogStopLoading = false;
        this.dialogVisibleStop = false;
      });
    },
    handleSizeChange(val) {
      this.parameters = false;
      this.pagesize = val;
      this.getAgreementList();
    },
    handleCurrentChange(val) {
      this.parameters = false;
      this.currentPage = val;
      this.getAgreementList();
    },
    tableRowClassName({ row, rowIndex }) {
      return "";
    },
    goEdit(row = "") {
      if (row && row.id) {
        this.$router.push({
          path: "/agreement",
          query: {
            id: row.id,
          },
        });
        const params = {
          limit: this.pagesize,
          page: this.currentPage,
          name: this.filter.name,
          market: this.filter.market,
          status: this.filter.status,
          scene: this.filter.scene,
          client: this.filter.client,
        };
        this.recordCurrentPage("agreementList0415", params);
      } else {
        this.$router.push("/agreement");
      }
    },
    sortChange(column, prop, order) {
      this.order_by = column.prop;
      this.sort ="ASC";
      this.getAgreementList();
    },
    /**
    * 获取协议类型
    */
    getScene(type) {
        return getScene(type);
    },
    /**
    * 获取当前协议在web的链接
    */
    getUrl(row) {
      let url = row.market === 'US'? env.webCom: env.webCn;
      return `${url}agreement?scene=${row.scene}`
    },
    /**
    *复制
    */
    onCopy(e) {
      this.$$success("复制成功");
    },
    onError(e) {
      this.$$error("复制失败");
    },
  },
  mounted() {
    this.msgBus.$on("agreementList", () => {
      this.recordCurrentPage("needUpdate", true);
    });
    this.init();
  },
};
</script>
<style lang="less">
@import "scss/aggrementManagement.less";
</style>
